export const getPasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (/[A-Z]/.test(password)) strength++;
    if (/[a-z]/.test(password)) strength++;
    if (/[0-9]/.test(password)) strength++;
    if (/[^A-Za-z0-9]/.test(password)) strength++;
    return strength;
}

export const validatePassword = (password) => {
    return {
        hasEightCharacters: password.length >= 8,
        hasUppercase: /[A-Z]/.test(password),
        hasLowercase: /[a-z]/.test(password),
        hasDigit: /[0-9]/.test(password),
        hasNonAlphanumeric: /[^A-Za-z0-9]/.test(password),
        strength: getPasswordStrength(password)
    }
}

export const getProgressVariant = (strength) => {
    if (strength <= 2) return 'danger';
    if (strength <= 4) return 'warning';
    return 'success';
}