import { useContext } from "react";
import ConfigContext from "../services/config.context";

export default function CopyrightComponent() {
    const { config } = useContext(ConfigContext);
    const clientName = config?.clientName;
    const domain = config?.domain;

    return (
        <p className="text-center mt-3">&copy; <span className="text-dark">{clientName}</span> 2023</p>
    )
}