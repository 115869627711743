import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../services/auth.context";
import axios from "axios";
import { Button, Card, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeCircleCheck, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ConfigContext from "../services/config.context";
import ErrorAlert from "./error.alert";

export default function ForgotPasswordComponent() {
    const { config } = useContext(ConfigContext);
    const { baseUrl } = useContext(AuthContext);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(null);
    const [ email, setEmail ] = useState("");
    const [ success, setSuccess ] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);

        axios.post(`${baseUrl}/forgot/${email}`)
        .then((res) => {
            setSuccess(res.data.message);
            setError(null);
        }, (err) => {
            setError(err.response.data.Message);
            setLoading(false);
        });
    }

    return (
        <>
            <ErrorAlert error={error} setError={setError} />
            <Card.Body>
                <Card.Header>
                    <Card.Title>Forgot Password</Card.Title>
                </Card.Header>
                <div className="text-center pt-3 pb-3">
                    { success === null && <FontAwesomeIcon className="text-primary" icon={faQuestionCircle} size="6x" /> }
                    { success !== null && <FontAwesomeIcon className="text-primary" icon={faEnvelopeCircleCheck} size="6x" />}
                </div>
                <Card.Text className="mt-3" hidden={success === null}>
                    An email has been sent with instructions to complete your account recovery process.
                </Card.Text>
                <Card.Text className="mt-3 text-muted" hidden={success !== null}>
                    Please complete the form below and an email will be sent to you with instructions.
                </Card.Text>
                <Form onSubmit={handleSubmit} hidden={success !== null}>
                    <Form.Group className="mt-3">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control 
                            type="email"
                            placeholder={`example@${config.domain}`}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required />
                    </Form.Group>
                    <Form.Group className="mt-3 d-flex flex-column flex-md-row justify-content-md-between">
                        <Link to="/login" className="text-end text-md-start mb-3 mb-md-0">
                            Log in
                        </Link>
                        <Link to="/register" className="text-end">
                            Create an account.
                        </Link>
                    </Form.Group>
                    <Form.Group className="mt-3 text-end">
                        <Button type="submit" variant="dark" className="mx-3" disabled={loading}>
                            Submit
                        </Button>
                        <Button type="cancel" variant="secondary" onClick={() => navigate(-1)}>
                            Cancel
                        </Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </>
    )
}