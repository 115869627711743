import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AuthContext from "../services/auth.context";
import { Alert, Container, Tab, Tabs, Spinner, Button } from "react-bootstrap";
import Welcome from "./welcome.component";
import PermissionsComponent from "./permissions.component";
import ActivityComponent from "./activity.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PasswordHandler } from "./passwordhandler.component";
import { faChartBar, faKey, faListCheck } from "@fortawesome/free-solid-svg-icons";
export default function UserDetails() {
    const { getUser, adminUpdateUserPassword } = useContext(AuthContext);
    const { userid } = useParams();
    const [userData, setUserData] = useState(null);
    const [activeTab, setActiveTab] = useState("general");
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [passwordUpdateStatus, setPasswordUpdateStatus] = useState({ message: '', variant: '' });
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [confirmPassword, setConfirmPassword] = useState("");

    useEffect(() => {
        const fetchUser = async () => {
            const data = await getUser(userid);
            setUserData(data.data);
        }
        fetchUser();
    }, [userid, getUser]);

    useEffect(() => {
        if (passwordUpdateStatus.message !== '') {
            const timer = setTimeout(() => {
                setPasswordUpdateStatus({ message: '', variant: '' });
            }, 3000);
            return () => {
                clearTimeout(timer);
            }
        }
    }, [passwordUpdateStatus]);

    useEffect(() => {
        setPasswordsMatch(password === confirmPassword);
    }, [password, confirmPassword]);

    const handleSubmit = (e) => {
        return new Promise((resolve, reject) => {
            e.preventDefault();
            setLoading(true);
            setErrorMessage(null);
            if (password !== confirmPassword) {
                setPasswordUpdateStatus({ message: 'New password and confirm password do not match', variant: 'danger' });
                setLoading(false);
                return;
            } else if (passwordStrength < 5) {
                setPasswordUpdateStatus({ message: 'Password strength is not sufficient', variant: 'danger' });
                setLoading(false);
                return;
            } else {
                adminUpdateUserPassword(userid, password)
                    .then(() => {
                        setPasswordUpdateStatus({ message: 'Password has been updated successfully!', variant: 'success' });
                        setPassword("");
                        setConfirmPassword("");
                        setLoading(false);
                    })
                    .catch((err) => {
                        setPasswordUpdateStatus({ message: errorMessage, variant: 'danger' });
                        setPassword("");
                        setConfirmPassword("");
                        setLoading(false);
                    });
            }
        });
    };

    const handlePasswordStrengthChange = (strength) => {
        setPasswordStrength(strength);
    };
    return <>
                {userData && (
                    <Welcome user={userData} details={userData} greeting="Viewing" />
                )}
                {/* {userid && <Alert variant="info">User ID: {userid}</Alert>} */}
                <Tabs defaultActiveKey="general" id="uncontrolled-tab-user" className="mb-3" onSelect={setActiveTab}>
                    <Tab eventKey="general" title="General" className="text-dark">
                        <Container fluid>
                            {userData && (
                                <>
                                    <p><strong>ID:</strong> {userid}</p>
                                    <p><strong>Email:</strong> {userData.email}</p>
                                    <p><strong>Email Confirmed:</strong> {userData.emailConfirmed ? 'Yes' : 'No'}</p>
                                    <p><strong>Created On:</strong> {new Date(userData.createdOn).toLocaleString()}</p>
                                    {/* <p><strong>Roles:</strong> {userData.roles && userData.roles.join(", ")}</p>
                                    <p><strong>Permissions:</strong> {userData.permissions && userData.permissions.join(", ")}</p> */}
                                </>
                            )}
                        </Container>
                    </Tab>
                    <Tab eventKey="password" title={<><FontAwesomeIcon icon={faKey} /> Password</>}>
                        {passwordUpdateStatus.message &&
                            <Alert variant={passwordUpdateStatus.variant}>
                                {passwordUpdateStatus.message}
                            </Alert>
                        }
                        <PasswordHandler
                            setPassword={setPassword}
                            setPasswordStrength={setPasswordStrength}
                            password={password}
                            confirmPassword={confirmPassword}
                            setConfirmPassword={setConfirmPassword}
                            onPasswordStrengthChange={handlePasswordStrengthChange}
                        />
                        <div className="text-end mt-3">
                            <Button type="submit" variant="dark" onClick={handleSubmit} disabled={loading || !passwordsMatch}>
                                {loading ? (
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                ) : (
                                    "Submit"
                                )}
                            </Button>
                        </div>
                    </Tab>
                    {userData && (
                        <Tab eventKey="permissions" title={<><FontAwesomeIcon icon={faListCheck} /> Permissions</>}>
                            <PermissionsComponent details={userData} showInput={true} />
                        </Tab>
                    )}
                    <Tab eventKey="activity" title={<><FontAwesomeIcon icon={faChartBar} /> Activity</>}>
                        <ActivityComponent userid={userid} activeTab={activeTab} />
                    </Tab>
                </Tabs>
    </>
}