import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Form, ProgressBar } from "react-bootstrap";
import { validatePassword, getProgressVariant } from "../utils/passwordUtils";

export function PasswordHandler({
    isChangePassword = false,
    isSignup = false,
    oldPassword,
    setOldPassword,
    setPassword,
    password,
    confirmPassword,
    setConfirmPassword,
    onPasswordStrengthChange,
    additionalFields = null,
}) {
    const [showPassword, setShowPassword] = useState(false);
    const [passwordValidation, setPasswordValidation] = useState({
        hasEightCharacters: false,
        hasUppercase: false,
        hasLowercase: false,
        hasDigit: false,
        hasNonAlphanumeric: false,
        strength: 0
    });

    const [passwordsMatch, setPasswordsMatch] = useState(true);

    useEffect(() => {
        setPasswordsMatch(password === confirmPassword);
    }, [password, confirmPassword]);

    const handleOldPasswordChange = (e) => {
        const oldPassword = e.target.value;
        setOldPassword(oldPassword);
    }

    const handlePasswordChange = (e) => {
        const password = e.target.value;
        setPassword(password);
        const validation = validatePassword(password);
        setPasswordValidation(validation);
        onPasswordStrengthChange(validation.strength);
    }

    const handleConfirmPasswordChange = (e) => {
        const confirmPassword = e.target.value;
        setConfirmPassword(confirmPassword);
    }

    return (
        <Form>
            {isChangePassword && (
                <Form.Group className="mt-3 position-relative">
                    <Form.Label>Old Password</Form.Label>
                    <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Please enter your old password"
                        value={oldPassword}
                        onChange={handleOldPasswordChange}
                        required
                    />
                    <div className="position-absolute end-0 px-4 d-flex" onClick={() => setShowPassword(!showPassword)} style={{
                        top: '42px',
                        cursor: 'pointer',
                    }}>
                        {showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                    </div>
                </Form.Group>
            )}

            {additionalFields}

            <Form.Group className="mt-3 position-relative">
                <Form.Label>Password</Form.Label>
                <ProgressBar now={passwordValidation.strength * 20} variant={getProgressVariant(passwordValidation.strength)} label={`Password Strength: ${passwordValidation.strength}/5`} className="mt-2" style={{
                    borderBottomLeftRadius: '0',
                    borderBottomRightRadius: '0',
                }} />
                <Form.Control
                    className="mb-2"
                    type={showPassword ? "text" : "password"}
                    placeholder="Please enter your password"
                    value={password}
                    onChange={handlePasswordChange}
                    style={{
                        borderTopLeftRadius: '0',
                        borderTopRightRadius: '0',
                    }}
                    required
                />
                <div className="position-absolute end-0 px-4 d-flex" onClick={() => setShowPassword(!showPassword)} style={{
                    top: '66px',
                    cursor: 'pointer',
                }}>
                    {showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                </div>
                <Form.Text className="text-muted">
                    <ul className="list-unstyled">
                        <li style={{ color: passwordValidation.hasEightCharacters && password !== "" ? "green" : "red" }}>{passwordValidation.hasEightCharacters && password !== "" ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />} At least 8 characters</li>
                        <li style={{ color: passwordValidation.hasUppercase && password !== "" ? "green" : "red" }}>{passwordValidation.hasUppercase && password !== "" ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />} Contains uppercase</li>
                        <li style={{ color: passwordValidation.hasLowercase && password !== "" ? "green" : "red" }}>{passwordValidation.hasLowercase && password !== "" ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />} Contains lowercase</li>
                        <li style={{ color: passwordValidation.hasDigit && password !== "" ? "green" : "red" }}>{passwordValidation.hasDigit && password !== "" ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />} Contains a digit</li>
                        <li style={{ color: passwordValidation.hasNonAlphanumeric && password !== "" ? "green" : "red" }}>{passwordValidation.hasNonAlphanumeric && password !== "" ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />} Contains a special character</li>
                    </ul>
                </Form.Text>
            </Form.Group>

            <Form.Group className="mt-3 position-relative">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Please confirm your password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    required
                />
                <div className="position-absolute end-0 px-4 d-flex" onClick={() => setShowPassword(!showPassword)} style={{
                    top: '43px',
                    cursor: 'pointer',
                }}>
                    {showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                </div>
                {!passwordsMatch && <p className="mt-2 text-danger">Passwords do not match.</p>}
            </Form.Group>
        </Form>
    );
}
