import { Alert } from "react-bootstrap";

export default function ErrorAlert(props) {
    const error = props?.error;
    const setError = props?.setError;
    return (
        <>
            {error !== null && (
                <Alert variant="danger" className="mt-3" onClose={() => setError(null)} dismissible>
                    {error}
                </Alert>
            )}
        </>
    );
}