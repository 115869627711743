import { faCheckCircle, faExclamationTriangle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import AuthContext from "../services/auth.context";
import ErrorAlert from "./error.alert";

export default function ConfirmEmailComponent() {
    const { baseUrl } = useContext(AuthContext);
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(null);
    const { email, token } = useParams();
    const [ requestSent, setRequestSent ] = useState(false);

    useEffect(() => {
        if (!requestSent) {
            setRequestSent(true);
            axios.post(`${baseUrl}/verify`, { email: email, token: token })
            .then((res) => {
                localStorage.removeItem("registrationEmail");
                setLoading(false);
            }, (err) => {
                setLoading(false);
                setError(err.response.data.Message);
            });
        }
    }, []);

    return (
        <>
            <Card.Body>
                <Card.Header>
                    <Card.Title>Activating Account</Card.Title>
                </Card.Header>
                <div className="text-center pt-3 pb-3">
                    <FontAwesomeIcon hidden={!loading} icon={faSpinner } size="6x" spin={true} />
                    <FontAwesomeIcon className="text-success" hidden={loading || error !== null} icon={faCheckCircle} size="6x" />
                    <FontAwesomeIcon className="text-danger" hidden={loading || error === null} icon={faExclamationTriangle} size="6x" />
                </div>

                <Card.Text hidden={loading || error !== null}>
                    Your account was activated. Please proceed to the <Link to="/login">Login</Link> page to access your account.
                </Card.Text>

                <ErrorAlert error={error} setError={setError} />
            </Card.Body>
        </>
    )
}