import { useContext, useState } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthContext from "../services/auth.context";
import ConfigContext from "../services/config.context";
import ErrorAlert from "./error.alert";

export default function LoginComponent() {
    const { config } = useContext(ConfigContext);
    const clientName = config?.clientName;
    const domain = config?.domain;

    const [ loading, setLoading ] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showError, setShowError] = useState(null);

    const { login } = useContext(AuthContext);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);

        setShowError(null);

        login(email, password)
        .then(() => {
            
        }, (err) => {
            setLoading(false);
            console.log(err);
            setShowError(err.response.data.Message);
        })
    };

    return (
        <>
            <ErrorAlert error={showError} setError={setShowError} />
            <Card.Body>
                <Card.Header>
                    <Card.Title>Log In</Card.Title>
                </Card.Header>
                <Card.Text className="text-muted mt-3">
                    Welcome to <span className="text-dark">{clientName}</span>, please enter your email address and password to continue.
                </Card.Text>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mt-3">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder={`example@${domain}`}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mt-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Please enter your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            />
                    </Form.Group>

                    <Form.Group className="mt-3 d-flex flex-column flex-md-row justify-content-md-between">
                        <Link to="/register" className="text-end text-md-start mb-3 mb-md-0">
                            Create an account.
                        </Link>
                        <Link to="/forgot-password" className="text-end">
                            Forgot password?
                        </Link>
                    </Form.Group>

                    <Form.Group className="mt-3 text-end">
                        <Button type="submit" variant="dark" className="mx-3" disabled={loading}>
                            Submit
                        </Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </>
    );
}
