import './App.scss';
import LoginComponent from './components/login.component';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { AuthContextProvider } from './services/auth.context';
import { CacheContextProvider } from './services/cache.context';
import PublicRoute from './services/public.route';
import ProtectedRoute from './services/protected.route';
import HomeComponent from './components/home.component';
import { ConfigContextProvider } from './services/config.context';
import RegisterComponent from './components/register.component';
import CheckEmailComponent from './components/check-email.component';
import ConfirmEmailComponent from './components/confirm-email.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import ForgotPasswordComponent from './components/forgot-password.component';
import ResetPasswordComponent from './components/reset-password.component';
import AdministrationComponent from './components/administration.component';
import UserDetails from './components/userdetails.component';
function App() {
  const navigate = useNavigate();

  return (
    <>
      <ConfigContextProvider>
        <AuthContextProvider>
          <Routes>
            {["", "login"].map((path, index) => {
                return (
                  <Route path={path} key={index} element={
                    <PublicRoute>
                      <LoginComponent />
                    </PublicRoute>
                  } />
                )
            })}

            <Route path="/register" element={
              <PublicRoute>
                <RegisterComponent />
              </PublicRoute>
            } />

            <Route path="/check-email" element={
              <PublicRoute>
                <CheckEmailComponent />
              </PublicRoute>
            } />

            <Route path="/confirm-account/:email/:token" element={
              <PublicRoute>
                <ConfirmEmailComponent />
              </PublicRoute>
            } />

            <Route path="/forgot-password" element={
              <PublicRoute>
                <ForgotPasswordComponent />
              </PublicRoute>
            } />

            <Route path="/reset-password/:email/:token" element={
              <PublicRoute>
                <ResetPasswordComponent />
              </PublicRoute>
            } />

            <Route path="/home" element={
              <ProtectedRoute>
                <HomeComponent />
              </ProtectedRoute>
            } />

            <Route path="/administration" element={
              <ProtectedRoute permission="user:write">
                <AdministrationComponent />
              </ProtectedRoute>
            } />

            <Route path="/administration/user/:userid" element={
              <ProtectedRoute permission="user:write">
                <UserDetails />
              </ProtectedRoute>
            } />

            <Route path="*" element={
              <>
                <h1>404 - Page Not Found</h1>
                <FontAwesomeIcon icon={faWarning} className="text-warning" size="6x" />
                <h5>The page you are trying to access does not exist. If this problem persists, please contact the site administrator for additional support.</h5>
                <h5>Click <a href="/" onClick={() => navigate(-1)}>here</a> to return.</h5>
              </>
            } />
          </Routes>
        </AuthContextProvider>
      </ConfigContextProvider>
    </>
  );
}

export default App;
