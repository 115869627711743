import { useContext, useState, useEffect } from "react";
import AuthContext from "../services/auth.context";
import PaginationComponent from "./pagination.component";
import PlaceholderCard from "./placeholdercard.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { Card, Table, Container, Alert, Collapse } from "react-bootstrap";

export default function ActivityComponent({ userid, activeTab, adminView }) {
    const { getUserAudit } = useContext(AuthContext);
    const [userAudit, setUserAudit] = useState(null);
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
    if (activeTab === "activity") {
        setIsLoading(true);
        const fetchUser = async () => {
            try {
                let userIdParam = null;
                let limit = 20;
                let isAdmin = false;

                if (userid && !adminView) {
                    userIdParam = userid;
                    limit = 10;
                } else if (adminView) {
                    isAdmin = true;
                    limit = 10;
                }

                const audit = await getUserAudit(userIdParam, page, limit, isAdmin);
                setUserAudit(audit.data);
            } catch (error) {
                console.error("Error fetching user audit:", error);

            } finally {
                setIsLoading(false);
            }
        };

        fetchUser();
    }
}, [activeTab, userid, page, adminView]);


    const handleToggle = index => {
        setOpen(prev => {
            const copy = [...prev];
            copy[index] = !copy[index];
            return copy;
        });
    };

    const renderContent = () => {
        if (isLoading) {
            return Array.from({ length: 2 }).map((_, index) => <PlaceholderCard numLines={10} showImage={false} height={100} key={index} />)
        } else if (userAudit && userAudit.results.length > 0) {
            return userAudit.results.map((activity, index) => (
                <Card key={activity.id} className="p-3 ms-0 me-0">
                    <Card.Header onClick={() => handleToggle(index)}>
                        <div className="btn btn-dark btn-sm pt-1 pb-1" style={{ float: 'right' }}>
                            {open[index] ?  <FontAwesomeIcon icon={faMinus} className="text-white" /> : <FontAwesomeIcon icon={faPlus} className="text-white" />}
                        </div>
                        <strong>Activity ID:</strong> {activity.id}
                        <br />
                        <strong>Type:</strong> {activity.auditType.name}
                        <br />
                        <strong>Timestamp:</strong> {new Date(activity.timestamp).toLocaleString()}
                    </Card.Header>
                    <Collapse in={open[index]}>
                        <div>
                            {activity.changes.length > 0 && (
                                <Card.Body>
                                    <Table striped bordered hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Field</th>
                                                <th hidden={activity.auditType.name !== 'Modified'}>Old Value</th>
                                                <th>New Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {activity.changes.map((change, idx) => (
                                                <tr key={idx}>
                                                    <td>{change.field}</td>
                                                    <td hidden={activity.auditType.name !== 'Modified'} style={{
                                                        maxWidth: '300px',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    
                                                    }}>{change.currentValue}</td>
                                                    <td style={{
                                                        maxWidth: '300px',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    
                                                    }}>{change.modifiedValue}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            )}
                        </div>
                    </Collapse>
                </Card>
            ));
        } else {
            return <Alert variant="info">No user activity found.</Alert>;
        }
    }

    return (
        <Container fluid>
            {renderContent()}
            {userAudit && userAudit.results.length > 0 && (
                <PaginationComponent
                    page={page}
                    totalItems={userAudit ? userAudit.totalItems : 0}
                    pageSize={userAudit ? userAudit.pageSize : 1}
                    setPage={setPage}
                />
            )}
        </Container>
    );
}
