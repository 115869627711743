import React from 'react';
import { Card } from 'react-bootstrap';
import ContentLoader from "react-content-loader";

const PlaceholderCard = ({ numLines = 3, showImage = false, height = 100 }) => (
  <Card className='mb-4 border-light shadow-sm'>
    <ContentLoader viewBox={`0 0 900 ${height}`} width="100%" height="100%">
      {showImage && <rect x="15" y="15" rx="5" ry="5" width="100" height="130" />}
      {Array.from({ length: numLines }, (_, i) => (
        <rect key={i} x="15" y={20 + 20 * i} rx="5" ry="5" width="855" height="9" />
      ))}
    </ContentLoader>
  </Card>
);

export default PlaceholderCard;
