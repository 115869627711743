import { useContext, useEffect, useState } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faUser, faRightFromBracket, faHouse, faScaleBalanced, faGauge, faBarcode } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import AuthContext from "../services/auth.context";
import ConfigContext from "../services/config.context";

export default function NavComponent() {
    const { config } = useContext(ConfigContext);
    const clientName = config?.clientName;
    const uiVersion = config?.version
    const { user, logout, hasPermission, originalAdminSession, revertToAdminSession } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (config) {
            setIsLoading(false);
        }
    }, [config]);

    const isAuthAdmin = hasPermission("user:write") || hasPermission("user:delete") || hasPermission("role:create") || hasPermission("role:delete");

    const navigate = useNavigate();

    if (isLoading) {
        return (
            <Navbar hidden={user === null} variant="dark" bg="dark" expand="lg" collapseOnSelect fixed="top" className="shadow-sm">
                <Container fluid className="justify-content-end p-3">
                    <Navbar.Brand className="mx-auto"></Navbar.Brand>
                </Container>
            </Navbar>
        );
    }

    return (
        <Navbar hidden={user === null} variant="dark" bg="dark" expand="lg" collapseOnSelect fixed="top" className="shadow-sm">
            <Container fluid className="justify-content-end">
            <Navbar.Brand className="mx-auto">{clientName} - v{uiVersion}</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        {isAuthAdmin && 
                        <Nav.Item className="mx-md-2 mb-3 mb-md-0">
                            <Button
                                variant="light btn-sm"
                                className="w-100"
                                type="button"
                                href={`${config.baseUrl}/portal/ui/dashboard`}
                            ><FontAwesomeIcon icon={faGauge} className="text-dark" /></Button>
                        </Nav.Item>
                        }
                        {isAuthAdmin && 
                        <Nav.Item className="mx-md-2 mb-3 mb-md-0">
                            <Button
                                variant="light btn-sm"
                                className="w-100"
                                type="button"
                                onClick={() => navigate("/administration")}
                            ><FontAwesomeIcon icon={faLock} className="text-dark" /></Button>
                        </Nav.Item>
                        }
                        {isAuthAdmin && 
                        <Nav.Item className="mx-md-2 mb-3 mb-md-0">
                            <Button
                                variant="light btn-sm"
                                className="w-100"
                                type="button"
                                href={`${config.baseUrl}/portal/ui/distribution`}
                            ><FontAwesomeIcon icon={faScaleBalanced} className="text-dark" /></Button>
                        </Nav.Item>
                        }
                        {isAuthAdmin && 
                        <Nav.Item className="mx-md-2 mb-3 mb-md-0">
                            <Button
                                variant="light btn-sm"
                                className="w-100"
                                type="button"
                                href={`${config.baseUrl}/portal/ui/skus`}
                            ><FontAwesomeIcon icon={faBarcode} className="text-dark" /></Button>
                        </Nav.Item>
                        }
                        <Nav.Item className="mx-md-2 mb-3 mb-md-0">
                            <Button 
                                variant="light btn-sm"
                                className="w-100"
                                type="button"
                                onClick={() => navigate("/home")}
                            ><FontAwesomeIcon icon={faUser} className="text-dark" /></Button>
                        </Nav.Item>
                        <Nav.Item className="mx-md-2 mb-3 mb-md-0">
                            <Button 
                                variant="light btn-sm"
                                className="w-100"
                                type="button"
                                href={`${config.baseUrl}/portal/ui`}
                            ><FontAwesomeIcon icon={faHouse} className="text-dark" /></Button>
                        </Nav.Item>
                        {originalAdminSession && 
                        <Nav.Item className="mx-md-2 mb-3 mb-md-0">
                            <Button
                                variant="warning btn-sm"
                                className="w-100"
                                type="button"
                                onClick={revertToAdminSession}
                            >Revert to Admin</Button>
                        </Nav.Item>
                        }
                        <Nav.Item className="mx-md-2 mb-3 mb-md-0">
                            <Button 
                                variant="light btn-sm"
                                className="w-100"
                                type="button"
                                onClick={() => logout()}
                            ><FontAwesomeIcon icon={faRightFromBracket} className="text-dark" /></Button>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
