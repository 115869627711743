import React from "react";
import { Pagination } from "react-bootstrap";

export default function PaginationComponent({ page, totalItems, pageSize, setPage }) {
    const totalPages = Math.ceil(totalItems / pageSize);

    const startPage = Math.max(1, page - 2);
    const endPage = Math.min(totalPages, page + 5);

    const pages = [];
    for (let number = startPage; number <= endPage; number++) {
        pages.push(
            <Pagination.Item key={number} active={number === page} onClick={() => setPage(number)}>
                {number}
            </Pagination.Item>
        );
    }

    return (
        <div className="sticky-bottom-pagination">
            <Pagination className="custom-pagination">
                <Pagination.First onClick={() => setPage(1)} disabled={page === 1} />
                <Pagination.Prev onClick={() => setPage(page - 1)} disabled={page === 1} />
                {/* {startPage > 1 && <Pagination.Ellipsis />} */}
                {pages}
                {/* {endPage < totalPages && <Pagination.Ellipsis />} */}
                <Pagination.Next onClick={() => setPage(page + 1)} disabled={page === totalPages} />
                <Pagination.Last onClick={() => setPage(totalPages)} disabled={page === totalPages} />
            </Pagination>
        </div>
    );
}
