import { useContext } from "react"
import AuthContext from "./auth.context"
import { Navigate } from "react-router-dom";


const ProtectedRoute = ({ children, permission }) => {
    const { user, hasPermission } = useContext(AuthContext);
    if (user) {
        if (permission !== null && permission !== undefined) {
            // Authorization check
            if (hasPermission(permission)) {
                return children;
            }

        } else { 
            // Authenticated check
            return children;
        }
    } 
    
    // TODO: Unauthorized page?
    return <Navigate to="/login"></Navigate>
}

export default ProtectedRoute;