import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { Card } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import AuthContext from "../services/auth.context";

export default function CheckEmailComponent() {
    const { resendConfirmation } = useContext(AuthContext);
    const [ resending, setResending ] = useState(false);
    const [ error, setError ] = useState(null);
    const emailAddress = localStorage.getItem("registrationEmail");

    const resend = async () => {
        setResending(true);
        
        resendConfirmation(emailAddress)
            .then(() => {
                setResending(false);
                setError(null);
            }, (err) => {
                setError(err.response.data.Message);
            });
    }

    return (
        <>
        { emailAddress === null && <Navigate to="/login" /> }
        <Card.Body>
            <Card.Header>
                <Card.Title>Account Created</Card.Title>
            </Card.Header>
            <Card.Text className="text-center text-warning">
                <FontAwesomeIcon icon={faEnvelope} size="6x" />
            </Card.Text>
            <Card.Text className="pt-3">
                An email was sent to <span className="text-primary">{emailAddress}</span>. Please follow the link in the email to complete your account activation.
            </Card.Text>
            <Card.Text>
                Don't see the email? Please allow up to <u>15 minutes</u> for the email to be sent and ensure you check your junk/spam email folders.
            </Card.Text>
            <Card.Text hidden={resending}>
                Still can't find it? Click <a href="#" onClick={resend}>here</a> to resend the activation email.
            </Card.Text>
            <Card.Text hidden={!resending}>
                { error !== null ? <span className="text-primary"><b>{error}</b></span> : (
                    <b>Email has been resent! If you are still experiencing issues, please contact support.</b>
                )}
            </Card.Text>
            <Card.Text className="text-center">
                <Link to="/login">
                    Return to Login.
                </Link>
            </Card.Text>
        </Card.Body>
</>
    );
}