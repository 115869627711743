import { useContext, useEffect } from "react";
import AuthContext from "./auth.context";
import { useNavigate } from "react-router-dom";

const PublicRoute = ({ children }) => {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (user !== null) {
            navigate("/home");
        }
    }, [user]);

    return children;
};

export default PublicRoute;