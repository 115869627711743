import { useContext, useState, useEffect } from "react";
import AuthContext from "../services/auth.context";
import PermissionsComponent from "./permissions.component";
import ActivityComponent from './activity.component';
import { PasswordHandler } from "./passwordhandler.component";
import Welcome from "./welcome.component";
import { Alert, Button, Spinner, Tab, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar, faKey, faListCheck} from "@fortawesome/free-solid-svg-icons";
export default function HomeComponent() {
    const { user, details, updatePassword, hasPermission } = useContext(AuthContext);
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [passwordUpdateStatus, setPasswordUpdateStatus] = useState({ message: '', variant: '' });
    const [loading, setLoading] = useState(false);
    const [updatedPassword, setUpdatedPassword] = useState(false);
    const [activeTab, setActiveTab] = useState("user");
    useEffect(() => {
        if (passwordUpdateStatus.message !== '') {
            const timer = setTimeout(() => {
                setPasswordUpdateStatus({ message: '', variant: '' });
            }, 3000);
            return () => {
                clearTimeout(timer);
            }
        }
    }, [passwordUpdateStatus]);

    useEffect(() => {
        setPasswordsMatch(password === confirmPassword);
    }, [password, confirmPassword]);

    const handlePasswordSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
    
        if (!passwordsMatch) {
            setPasswordUpdateStatus({ message: 'New password and confirm password do not match', variant: 'danger' });
            setLoading(false);
            return;
        }
    
        if (passwordStrength !== 5) {
            setPasswordUpdateStatus({ message: 'Password strength is not sufficient', variant: 'danger' });
            setLoading(false);
            return;
        }
        try {
            await updatePassword(oldPassword, password);
            setPasswordUpdateStatus({ message: 'Password has been updated successfully!', variant: 'success' });
            setOldPassword("");
            setPassword("");
            setConfirmPassword("");
            setLoading(false);
            setUpdatedPassword(true);
        } catch (err) {
            console.log(err.message)
            let errorMessage = err.message;
            if(err.response && err.response.data && err.response.data.Message) {
                errorMessage = err.response.data.Message;
            }
            setPasswordUpdateStatus({ message: errorMessage, variant: 'danger' });
            setOldPassword("");
            setPassword("");
            setConfirmPassword("");
            setLoading(false);
            setUpdatedPassword(false);
        }
    };
    
    
    const handlePasswordStrengthChange = (strength) => {
        setPasswordStrength(strength);
    };
    return (
        <>
                    <Welcome user={user} details={details} />
                    <Tabs defaultActiveKey="password" id="uncontrolled-tab-example" className="mb-3 sticky-top-tabs border border-grey rounded shadow-sm pt-1 px-1 bg-light" onSelect={setActiveTab}>
                        <Tab eventKey="password" title={<><FontAwesomeIcon icon={faKey} /> Password</>}>
                            <div className="p-3">
                                {passwordUpdateStatus.message &&
                                    <Alert variant={passwordUpdateStatus.variant}>
                                        {passwordUpdateStatus.message}
                                    </Alert>
                                }
                                <h4>Password</h4>
                                <PasswordHandler
                                    isChangePassword={true}
                                    oldPassword={oldPassword}
                                    setOldPassword={setOldPassword}
                                    setPassword={setPassword}
                                    password={password}
                                    confirmPassword={confirmPassword}
                                    setConfirmPassword={setConfirmPassword}
                                    onPasswordStrengthChange={handlePasswordStrengthChange}
                                />
                                <div className="text-end mt-3">
                                    <Button type="submit" variant="dark" onClick={handlePasswordSubmit} disabled={loading || !passwordsMatch}>
                                        {loading ? (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                <span className="sr-only">Loading...</span>
                                            </>
                                        ) : (
                                            'Update Password'
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="permissions" title={<><FontAwesomeIcon icon={faListCheck} /> Permissions</>}>
                            <PermissionsComponent details={details} showInput={false}/>
                        </Tab>
                       
                        <Tab eventKey="activity" title={<><FontAwesomeIcon icon={faChartBar} /> Activity</>}>
                            <ActivityComponent activeTab={activeTab} />
                        </Tab>
   
                    </Tabs>
        </>
    )
}