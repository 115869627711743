import { useContext, useState } from "react"
import AuthContext from "../services/auth.context"
import { Tab, Tabs } from "react-bootstrap";
import UsersTable from './userstable.component';
import Welcome from "./welcome.component";
import ActivityComponent from "./activity.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faChartBar} from "@fortawesome/free-solid-svg-icons";
export default function AdministrationComponent() {
    const { user, details } = useContext(AuthContext);
    const [activeTab, setActiveTab] = useState("users");
    return <>

                <Welcome user={user} details={details} />
                <h2>Administration</h2>
                <Tabs defaultActiveKey="users" id="uncontrolled-tab-administration" className="mb-3 sticky-top-tabs border border-grey rounded shadow-sm pt-1 px-1 bg-light" onSelect={setActiveTab}>
                    <Tab eventKey="users" title={<><FontAwesomeIcon icon={faUsers} /> Users</>}>
                        <UsersTable />
                    </Tab>
                    <Tab eventKey="activity" title={<><FontAwesomeIcon icon={faChartBar} /> Activity</>}>
                        <ActivityComponent  adminView={true} activeTab={activeTab} />
                    </Tab>
                </Tabs>
    </>
}
