import axios from "axios";
import { createContext, useContext, useEffect, useState, useMemo } from "react"; // Added useMemo to imports
import ConfigContext from "./config.context";
import authHeader from "./auth.header";

const CacheContext = createContext();

export const CacheContextProvider = ({ children }) => {
    const { config } = useContext(ConfigContext);
    const userUrl = `${config?.authUrl}/v1/user`;
    const baseUrl = `${config?.baseUrl}`;
    const [loading, setLoading] = useState(true);
    const [statuscache, setStatusCache] = useState(null);
    const [userscache, setUsersCache] = useState(null);
    const [productscache, setProductsCache] = useState(null);
    const [productshippingprofiles, setProductShippingProfiles] = useState(null);

    const fetchData = (url, setter) => {
        axios.get(url, authHeader())
            .then((res) => {
                setter(res.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        if (!userscache) {
            const payload = { page: 1, pageSize: 1000 };
            const token = localStorage.getItem("accessToken");
            axios.post(`${userUrl}/search`, payload, {
                headers: { 'Authorization': `Bearer ${token}` },
            })
            .then((res) => setUsersCache(res.data))
            .catch((error) => console.error(error));
        }
    }, [userUrl, userscache]);

    useEffect(() => {
        if (!statuscache) fetchData(`${baseUrl}/portal/api/v1/lookup/workobject/status`, setStatusCache);
        if (!productshippingprofiles) fetchData(`${baseUrl}/portal/api/v1/lookup/shopify/shippingprofiles`, setProductShippingProfiles);
        if (!productscache) fetchData(`${baseUrl}/portal/api/v1/lookup/shopify/products`, setProductsCache);
    }, [baseUrl, statuscache, productscache, productshippingprofiles]);

    useEffect(() => {
        if (userscache && statuscache && productscache && productshippingprofiles) setLoading(false);
    }, [userscache, statuscache, productscache, productshippingprofiles]);

    const contextValue = useMemo(() => ({
        loading,
        statuscache,
        userscache,
        productscache,
        productshippingprofiles,
    }), [loading, statuscache, userscache, productscache, productshippingprofiles]); // Added useMemo

    return (
        <CacheContext.Provider value={contextValue}>
            {!loading && children}
        </CacheContext.Provider>
    );
};

export default CacheContext;
