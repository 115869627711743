import { useContext, useEffect, useState, useCallback } from "react"
import { Link } from "react-router-dom";
import AuthContext from "../services/auth.context"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark, faEye, faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import PaginationComponent from "./pagination.component";
import { Table, Badge, Button } from "react-bootstrap";
import { InputTags } from 'react-bootstrap-tagsinput'
import 'react-bootstrap-tagsinput/dist/index.css'
export default function UsersTable() {
    const { getUsers, impersonateUser, revertToAdminSession, hasPermission } = useContext(AuthContext);
    const [usersData, setUsersData] = useState({results: [], totalItems: 0, pageSize: 1, page: 1});
    const [page, setPage] = useState(1);
    const [searchEmails, setSearchEmails] = useState([]);

    const fetchUsers = useCallback(async (emails) => {
        const data = await getUsers(emails, page);
        setUsersData(data.data);
    }, [getUsers, page]);

    useEffect(() => {
        fetchUsers([]);
    }, [fetchUsers, page]);
    
    const isAuthAdmin = hasPermission("user:write") || hasPermission("user:delete") || hasPermission("role:create") || hasPermission("role:delete");

    const handleImpersonate = async (userId) => {
        await impersonateUser(userId);
    };

    const handleRevert = () => {
        revertToAdminSession();
    };

    return (
        <>
            <div className="d-flex justify-content-between">
                <div className='input-group mb-3'>
                    <InputTags
                        values={searchEmails}
                        onTags={(value) => setSearchEmails(value.values)}
                    />
                    <button
                        className='btn btn-outline-dark'
                        type='button'
                        data-testid='button-clearAll'
                        onClick={() => {
                            setSearchEmails([]);
                            fetchUsers([]);
                        }}
                    >
                        Clear
                    </button>
                    <button
                        className='btn btn-outline-dark'
                        type='button'
                        data-testid='button-search'
                        onClick={() => {
                            fetchUsers(searchEmails);
                        }}
                    >
                        Search
                    </button>
                </div>
            </div>
            <Table striped bordered hover responsive className="rounded-table shadow-sm">
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Email Confirmed</th>
                        <th>Registered</th>
                        <th>Recent Login</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {usersData.results.map((user) => (
                        <tr key={user.id}>
                            <td>{user.metadata.firstName}</td>
                            <td>{user.metadata.lastName}</td>
                            <td>{user.email}</td>
                            <td>
                                <Badge className={user.emailConfirmed ? "bg-success" : "bg-danger"}>
                                    {user.emailConfirmed ? <FontAwesomeIcon icon={faCircleCheck}/> : <FontAwesomeIcon icon={faCircleXmark} />}
                                </Badge>
                            </td>
                            <td>{new Date(user.createdOn).toLocaleDateString()}</td>
                            <td>{new Date(user.lastAccessedOn).toLocaleString()}</td>
                            <td>
                                <Link to={`/administration/user/${user.id}`}>
                                    <Button variant="dark" className="btn-sm"><FontAwesomeIcon icon={faEye}/></Button>
                                </Link>
                                {isAuthAdmin && (
                                    <Button variant="dark" className="btn-sm ms-2" onClick={() => handleImpersonate(user.id)}><FontAwesomeIcon icon={faRightToBracket}/></Button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <PaginationComponent
                page={page}
                totalItems={usersData ? usersData.totalItems : 0}
                pageSize={usersData ? usersData.pageSize : 1}
                setPage={setPage}
            />

        </>
    )
}
