import { useContext, useState } from "react";
import AuthContext from "../services/auth.context";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Card, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faKey } from "@fortawesome/free-solid-svg-icons";
import ErrorAlert from "./error.alert";
import axios from "axios";

export default function ResetPasswordComponent() {
    const { baseUrl } = useContext(AuthContext);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(null);
    const { email, token } = useParams();
    const [ password, setPassword ] = useState("");
    const [ confirm, setConfirm ] = useState("");
    const [ success, setSuccess ] = useState(null);

    const nagivate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();

        setLoading(true);

        if (password !== confirm) {
            setError("Passwords do not match!");
            setLoading(false);
        } else {
            axios.post(`${baseUrl}/reset`, {
                email: email,
                token: token,
                password: password
            }).then((res) => {
                setError(null);
                setSuccess(res.data.Message);
            }, (err) => {
                setLoading(false);
                setError(err.response.data.Message);
            });
        }
    }

    return (
        <>
            <ErrorAlert error={error} setError={setError} />
            <Card.Body>
                <Card.Header>
                    <Card.Title>Reset Password</Card.Title>
                </Card.Header>

                <div className="text-center pt-3 pb-3">
                    { success === null && <FontAwesomeIcon className="text-primary" icon={faKey} size="6x"/> }
                    { success !== null && <FontAwesomeIcon className="text-success" icon={faCheckCircle} size="6x"/> }
                </div>

                <Card.Text className="mt-3" hidden={success === null}>
                    Your password was updated. Please click <Link to="/login">here</Link> to login to your account!
                </Card.Text>

                <Card.Text className="mt-3 text-muted" hidden={success !== null}>
                    Please complete the form below to change your password.
                </Card.Text>

                <Form onSubmit={handleSubmit} hidden={success !== null}>
                    <Form.Group className="mt-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" value={email} readOnly disabled />
                    </Form.Group>

                    <Form.Group className="mt-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Enter new password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    </Form.Group>

                    <Form.Group className="mt-3">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" placeholder="Confirm new password" value={confirm} onChange={(e) => setConfirm(e.target.value)} required />
                    </Form.Group>
                    <Form.Group className="mt-3 d-flex flex-column flex-md-row justify-content-md-between">
                        <Link to="/login" className="text-end text-md-start mb-3 mb-md-0">
                            Already have an account? Log in
                        </Link>
                        <Link to="/register" className="text-end">
                            Create an account.
                        </Link>
                    </Form.Group>
                    <Form.Group className="mt-3 text-end">
                        <Button type="submit" variant="dark" className="mx-3" disabled={loading}>
                            Submit
                        </Button>
                        <Button variant="secondary" onClick={() => nagivate("/login")}>
                            Cancel
                        </Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </>
    )
}