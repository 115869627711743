import axios from "axios";
import { createContext, useEffect, useState } from "react";

const ConfigContext = createContext();

export const ConfigContextProvider = ({children}) => {
    const [ config, setConfig ] = useState(null);

    useEffect(() => {
        axios.get("/idm/conf/config.json")
            .then((res) => {
                setConfig(res.data);
            });

    }, []);

    return (
        <ConfigContext.Provider value={{config}}>
            {config !== null && children}
        </ConfigContext.Provider>
    )
};

export default ConfigContext;