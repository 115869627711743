import { Card } from "react-bootstrap";

export default function Welcome({ user, details, greeting = "Welcome"}) {
    return (
        <>
            {user !== null && details !== null && (
                <div className="d-flex justify-content-between flex-column flex-md-row">
                    <Card.Title>{greeting} {user.email}</Card.Title>
                </div>
            )}
            <hr/>
        </>
    );
}