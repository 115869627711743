export default function authHeader() {
    const token = localStorage.getItem('accessToken');
    if (token) {
        return { 
            headers : {
                Authorization: 'Bearer ' + token
            }
        };
    }

    return {};
}