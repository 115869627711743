// RegisterComponent.js
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../services/auth.context";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import ConfigContext from "../services/config.context";
import { useNavigate } from "react-router-dom";
import ErrorAlert from "./error.alert";
import { PasswordHandler } from "./passwordhandler.component";

export default function RegisterComponent() {
    const { config } = useContext(ConfigContext);
    const clientName = config?.clientName;
    const domain = config?.domain;

    const [email, setEmail] = useState("");
    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [confirmPassword, setConfirmPassword] = useState("");

    const { register } = useContext(AuthContext);
    
    const navigate = useNavigate();

    const allFieldsFilled = email !== "" && firstname !== "" && lastname !== "" && password !== "" && confirmPassword !== "";

    const handleSubmit = (e) => {
        return new Promise((resolve, reject) => {
            e.preventDefault();
            setLoading(true);
            setErrorMessage(null);
            if (password !== confirmPassword) {
                handleError("Error, passwords do not match!");
                setLoading(false);
                reject(new Error("Passwords do not match!"));
            } else if (passwordStrength < 5) {
                handleError("Error, password does not meet the requirements!");
                setLoading(false);
                reject(new Error("Password does not meet the requirements!"));
            } else {
                register(email, password, firstname, lastname)
                    .then(() => {
                        localStorage.setItem("registrationEmail", email);
                        navigate("/check-email");
                        resolve();
                    })
                    .catch((err) => {
                        handleError(err.response?.data?.Message || err.message);
                        setLoading(false);
                        reject(err);
                    });
            }
        });
    };

    const handleError = (error) => {
        setErrorMessage(error);
    };

    const handlePasswordStrengthChange = (strength) => {
        setPasswordStrength(strength);
    };

    return (
        <>
            <ErrorAlert error={errorMessage} setError={setErrorMessage} />
            <Card.Body>
                <Card.Header>
                    <Card.Title>Sign up</Card.Title>
                </Card.Header>
                <Card.Text className="text-muted mt-3">
                    Welcome to <span className="text-dark">{clientName}</span>, please complete the form below to create a new account.
                </Card.Text>
                <div>
                    <div className="d-flex justify-content-around">
                <Form.Group className="mt-3 me-1 w-50">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={`John`}
                            value={firstname}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mt-3 w-50 ms-1">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={`John`}
                            value={lastname}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                    </Form.Group>
                    </div>
                    <Form.Group className="mt-3">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder={`example@${domain}`}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <PasswordHandler
                        setPassword={setPassword}
                        setPasswordStrength={setPasswordStrength}
                        password={password}
                        confirmPassword={confirmPassword}
                        setConfirmPassword={setConfirmPassword}
                        onPasswordStrengthChange={handlePasswordStrengthChange}
                    />
                    <Form.Group className="mt-3 d-flex flex-column flex-md-row justify-content-md-between">
                        <Link to="/login" className="text-end text-md-start mb-3 mb-md-0">
                            Already have an account? Log in
                        </Link>
                        <Link to="/forgot-password" className="text-end">
                            Forgot password?
                        </Link>
                    </Form.Group>
                    <div className="text-end mt-3">
                        <Button type="submit" variant="dark" onClick={handleSubmit}  disabled={!allFieldsFilled || loading}>
                            {loading ? (
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            ) : (
                                "Submit"
                            )}
                        </Button>
                    </div>
                </div>
            </Card.Body>
        </>
    );
}

