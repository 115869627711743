import { useContext, useState, useEffect } from "react";
import AuthContext from "../services/auth.context";
import { Alert, Table, Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";
import { InputTags } from 'react-bootstrap-tagsinput'
import 'react-bootstrap-tagsinput/dist/index.css'
import Swal from 'sweetalert2';

export default function PermissionsComponent({ details, showInput }) {
    const { getPermissions, hasPermission, setUserRoles, removeUserRoles } = useContext(AuthContext);
    const [ permissions, setPermissions ] = useState([]);
    const [ categories, setCategories ] = useState([]);
    const [ roles, setRoles ] = useState([]);
    const [ allRoles, setAllRoles ] = useState([]);
    const [ rolePermissions, setRolePermissions ] = useState([]);
    const [ prevRoles, setPrevRoles ] = useState([]);
    const [ saving, setSaving ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ changesMade, setChangesMade ] = useState(false);
    const isAuthAdmin = hasPermission("user:write") || hasPermission("user:delete") || hasPermission("role:create") || hasPermission("role:delete");

    useEffect(() => {
        const fetchPermissions = async () => {
            const data = await getPermissions();
            const perms = new Set();
            const cats = new Set();
            const rolePerms = new Set();
            data.data.permissions.forEach(p => {
                const [cat, perm] = p.split(':');
                cats.add(cat);
                perms.add(perm);
            });
            setPermissions(Array.from(perms));
            setCategories(Array.from(cats));
            setRoles(details.roles);
            setAllRoles(data.data.roles);
            setChangesMade(false);
            details.roles.forEach(role => {
                const roleData = data.data.roles.find(r => r.name === role);
                if (roleData) {
                    roleData.permissions.forEach(p => rolePerms.add(p));
                }
            });
            setRolePermissions(Array.from(rolePerms));
        }
        fetchPermissions();
    }, [getPermissions, details.roles]);

    useEffect(() => {
        setPrevRoles(details.roles);
    }, [details.roles]);


    const hasConflictingRoles = (newRole, existingRoles) => {
        const conflictingRoles = ['Artist', 'Designer'];
        if (conflictingRoles.includes(newRole)) {
            return existingRoles.some(role => conflictingRoles.includes(role));
        }
        return false;
    };
    

    
    const handleRoleClick = (role) => {
        if (hasConflictingRoles(role.name, roles)) {
            setAlertMessage({ type: 'warning', text: 'Users may not be assigned to both Artist and Designer.' });
            return;
        }
        setRoles(oldRoles => [...oldRoles, role.name]);
        setRolePermissions(oldPermissions => [...new Set([...oldPermissions, ...role.permissions])]);
        setChangesMade(true);
    };
    

    const handleRoleChanges = (newRoles) => {
        if (newRoles.some(newRole => hasConflictingRoles(newRole, newRoles))) {
            setAlertMessage({ type: 'warning', text: 'Users may not be assigned to both Artist and Designer.' });
            return;
        }
        const addedRoles = newRoles.filter(role => !roles.includes(role));
        const removedRoles = roles.filter(role => !newRoles.includes(role));

        // Only change the 'changesMade' state if a role has been added or removed
        if (addedRoles.length > 0 || removedRoles.length > 0) {
            setChangesMade(true);
        }

        setRoles(newRoles);
        const newRolePermissions = newRoles.reduce((perms, role) => {
            const roleData = allRoles.find(r => r.name === role);
            return roleData ? [...new Set([...perms, ...roleData.permissions])] : perms;
        }, []);
        setRolePermissions(newRolePermissions);
    };


    const handleSave = async () => {
        const addedRoles = roles.filter(role => !prevRoles.includes(role));
        const removedRoles = prevRoles.filter(role => !roles.includes(role));

        Swal.fire({
            title: 'Are you sure?',
            html: `
                <p>You are about to update user roles. This action cannot be undone.</p>
                ${addedRoles.length > 0 ? `<p><strong>Roles Added:</strong> ${addedRoles.join(', ')}</p>` : ''}
                ${removedRoles.length > 0 ? `<p><strong>Roles Removed:</strong> ${removedRoles.join(', ')}</p>` : ''}
            `,
            icon: 'warning',
            showCancelButton: true,
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            confirmButtonText: 'Yes, update!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setSaving(true);
                setAlertMessage(null);
                setChangesMade(false);
                try {
                    for (const role of removedRoles) {
                        await removeUserRoles(details.id, role);
                    }

                    for (const role of addedRoles) {
                        await setUserRoles(details.id, role);
                    }

                    setPrevRoles(roles);
                    setAlertMessage({ type: "success", text: "Roles updated successfully!" });
                } catch (error) {
                    setAlertMessage({ type: "danger", text: "Error updating roles. Please try again." });
                }
                setSaving(false);
            }
        });
    };


    return (
        <div className="p-3">
            {isAuthAdmin && showInput && (
                <>
                    {alertMessage && (
                        <Alert variant={alertMessage.type} dismissible transition>
                            {alertMessage.text}
                        </Alert>
                    )}
                    <h4>Roles</h4>
                    <div className='input-group mb-3'>
                        <InputTags
                            elementClassName="bg-dark text-white"
                            values={roles}
                            onTags={(value) => handleRoleChanges(value.values)}
                        />
                        <button
                            className='btn btn-outline-dark'
                            type='button'
                            data-testid='button-clearAll'
                            onClick={() => {
                                setRoles([]);
                                setRolePermissions([]);
                                setChangesMade(true);
                            }}
                        >
                            Clear
                        </button>
                        <button
                            className={changesMade ? 'btn btn-dark' : 'btn btn-outline-dark'}
                            type='button'
                            data-testid='button-save'
                            onClick={handleSave}
                            disabled={saving}
                        >
                            {saving ? <Spinner animation="border" size="sm" /> : "Save"}
                        </button>
                    </div>
                    {allRoles.map(role => (
                        !roles.includes(role.name) && (
                            <Button
                                variant="dark"
                                onClick={() => handleRoleClick(role)}
                                className="m-2 btn-sm"
                                disabled={hasConflictingRoles(role.name, roles)}
                            >
                                <FontAwesomeIcon icon={faPlus} /> {role.name}
                            </Button>
                        )
                    ))}
                </>
            )}
            <h4>Permissions</h4>
            <hr />
            <Table striped bordered hover className="mt-3 rounded-table shadow-sm">
                <thead>
                    <tr>
                        <th>Entity</th>
                        {permissions.map((perm, index) => <th key={`perm-${index}`} className="text-center">{perm}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {categories.map((category, catIndex) => (
                        <tr key={`cat-${catIndex}`}>
                            <td>{category}</td>
                            {permissions.map((perm, permIndex) => (
                                <td key={`perm-${catIndex}-${permIndex}`} className="text-center">
                                    {rolePermissions.includes(`${category}:${perm}`) ?
                                        <FontAwesomeIcon icon={faCheck} className="text-success" /> :
                                        <FontAwesomeIcon icon={faTimes} className="text-danger" />
                                    }
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>

            </Table>
        </div>
    );
}
