import { createContext, useContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jwtDecode from "jwt-decode";
import CopyrightComponent from "../components/copyright.component";
import NavComponent from "../components/nav.component";
import ConfigContext from "./config.context";
import authHeader from "./auth.header";
import { Card, Container } from "react-bootstrap";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const { config } = useContext(ConfigContext);
    const baseUrl = `${config?.authUrl}/v1/authentication`;
    const userUrl = `${config?.authUrl}/v1/user`;
    const auditUrl = `${config?.authUrl}/v1/audit`;
    const permissionsUrl = `${config?.authUrl}/v1/permission`;
    const rolesUrl = `${config?.authUrl}/v1/role`;
    const navigate = useNavigate();

    const [details, setDetails] = useState(() => {
        if (localStorage.getItem("userDetails")) {
            const details = JSON.parse(localStorage.getItem("userDetails"));
            return details;
        }
        return null;
    });

    const [originalAdminSession, setOriginalAdminSession] = useState(() => {
        if (localStorage.getItem("adminSession")) {
            return JSON.parse(localStorage.getItem("adminSession"));
        }
        return null;
    });

    const logout = useCallback(async () => {
        setUser(null);
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("refreshTokenExpiresOn");
        localStorage.removeItem("adminSession");
        navigate("/login");
    }, [navigate]);

    const refreshAccessToken = useCallback(async () => {
        const refreshToken = localStorage.getItem("refreshToken");

        try {
            const response = await axios.post(`${baseUrl}/refresh/${refreshToken}`, authHeader());

            if (response.data.accessToken) {
                localStorage.setItem("accessToken", response.data.accessToken);
                localStorage.setItem("refreshToken", response.data.refreshToken);
                localStorage.setItem("refreshTokenExpiresOn", response.data.refreshTokenExpiresOn);
                setUser(jwtDecode(response.data.accessToken));
            } else {
                logout();
            }
        } catch (error) {
            logout();
        }
    }, [baseUrl, logout]);

    const [user, setUser] = useState(() => {
        if (localStorage.getItem("accessToken")) {
            const accessToken = localStorage.getItem('accessToken');
            const refreshTokenExpiresOn = new Date(localStorage.getItem("refreshTokenExpiresOn"));
            const details = jwtDecode(accessToken);
            const expiresOn = details.exp * 1000;
            const now = Date.now();

            if (now > expiresOn - 60000) {
                refreshAccessToken();
            } else if (now > refreshTokenExpiresOn) {
                logout();
            }

            return details;
        }
        return null;
    });

    const login = async (email, password) => {
        const response = await axios.post(`${config?.baseUrl}/api/v1/authentication/login`, { email: email, password: password });
        if (response.data.accessToken) {
            localStorage.setItem("accessToken", response.data.accessToken);
            localStorage.setItem("refreshToken", response.data.refreshToken);
            localStorage.setItem("refreshTokenExpiresOn", response.data.refreshTokenExpiresOn);
            localStorage.setItem("userDetails", JSON.stringify(response.data.user));
            setUser(jwtDecode(response.data.accessToken));
            setDetails(response.data.user);
            if (config.loginSuccessRedirect) {
                window.location.href = `${config?.baseUrl}/idm/home`;
            } else {
                navigate("/home");
            }
        }
    };

    const impersonateUser = async (userId) => {
        const adminSession = {
            accessToken: localStorage.getItem("accessToken"),
            refreshToken: localStorage.getItem("refreshToken"),
            refreshTokenExpiresOn: localStorage.getItem("refreshTokenExpiresOn"),
            user: user,
            details: details,
        };

        const response = await axios.get(`${baseUrl}/${userId}/impersonate`, authHeader());
        if (response.data.accessToken) {
            localStorage.setItem("adminSession", JSON.stringify(adminSession));
            localStorage.setItem("accessToken", response.data.accessToken);
            localStorage.setItem("refreshToken", response.data.refreshToken);
            localStorage.setItem("refreshTokenExpiresOn", response.data.refreshTokenExpiresOn);
            localStorage.setItem("userDetails", JSON.stringify(response.data.user));
            setUser(jwtDecode(response.data.accessToken));
            setDetails(response.data.user);
        }
    };

    const revertToAdminSession = () => {
        const adminSession = JSON.parse(localStorage.getItem("adminSession"));
        if (adminSession) {
            localStorage.setItem("accessToken", adminSession.accessToken);
            localStorage.setItem("refreshToken", adminSession.refreshToken);
            localStorage.setItem("refreshTokenExpiresOn", adminSession.refreshTokenExpiresOn);
            localStorage.setItem("userDetails", JSON.stringify(adminSession.details));
            setUser(adminSession.user);
            setDetails(adminSession.details);
            localStorage.removeItem("adminSession");
            setOriginalAdminSession(null);
        }
    };

    useEffect(() => {
        const checkTokenExpiration = async () => {
            const accessToken = localStorage.getItem("accessToken");
            if (!accessToken) return;
            const details = jwtDecode(accessToken);
            const expiresOn = details.exp * 1000;
            const now = Date.now();

            if (now > expiresOn - 60000) {
                await refreshAccessToken();
            }
        };

        const intervalId = setInterval(checkTokenExpiration, 60000);

        return () => clearInterval(intervalId);
    }, [refreshAccessToken]);

    const register = async (email, password, firstname, lastname) => {
        return await axios.post(`${baseUrl}/register`, {
            email: email,
            password: password,
            metadata: {
                firstName: firstname,
                lastName: lastname
            }
        });
    }

    const resendConfirmation = async (email) => {
        return await axios.post(`${baseUrl}/resend`, { email: email });
    }

    const hasPermission = (permission) => {
        if (!user.role) return false;
        return user.role.indexOf(permission) > -1;
    }

    const updatePassword = async (oldPassword, newPassword) => {
        return await axios.post(`${baseUrl}/password`,
            {
                password: oldPassword,
                requestedPassword: newPassword,
            },
            authHeader()
        );
    }

    const adminUpdateUserPassword = async (id, password) => {
        return await axios.patch(`${userUrl}/${id}/password`,
            {
                password: password,
            },
            authHeader()
        );
    }

    const getUsers = async (emails, pageNumber = 1, pageSize = 10) => {
        let payload = {
            page: pageNumber,
            pageSize: pageSize,
        };

        if (emails != null) {
            payload.emails = emails;
        }
        return await axios.post(`${userUrl}/search`,
            payload,
            authHeader()
        );
    }

    const getUser = async (id) => {
        return await axios.get(`${userUrl}/${id}`, authHeader());
    }

    const getUserAudit = async (id, pageNumber = 1, pageSize = 10, adminView) => {
        let url = `${auditUrl}/history`;
        let payload = {
            page: pageNumber,
            pageSize: pageSize,
        };

        if (id != null) {
            url = `${auditUrl}/search`;
            payload.userId = id;
            payload.includeChanges = true;
        }

        if (adminView && id === null) {
            url = `${auditUrl}/search`;
            payload.includeChanges = true;
        }

        return await axios.post(url, payload, authHeader());
    }

    const getPermissions = async () => {
        const permissions = await axios.get(`${permissionsUrl}`, authHeader());
        const roles = await axios.get(`${rolesUrl}`, authHeader());
        return { data: { permissions: permissions.data.permissions, roles: roles.data } };
    }

    const setUserRoles = async (id, role) => {
        const token = localStorage.getItem("accessToken");
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${userUrl}/${id}/role/${role}`,
            headers: {
                'accept': 'text/plain',
                'Roles': 'user:write,role:write',
                'Authorization': `Bearer ${token}`
            }
        }
        return await axios(config);
    }

    const removeUserRoles = async (id, role) => {
        const token = localStorage.getItem("accessToken");
        const config = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: `${userUrl}/${id}/role/${role}`,
            headers: {
                'accept': 'text/plain',
                'Roles': 'user:write,role:write',
                'Authorization': `Bearer ${token}`
            }
        }
        return await axios(config);
    }

    if (user !== null) {
        const expiresOn = user.exp * 1000;
        const now = Date.now();
        const refreshTokenExpiresOn = new Date(localStorage.getItem("refreshTokenExpiresOn"));

        if (now > expiresOn - 60000) {
            refreshAccessToken();
        } else if (now > refreshTokenExpiresOn) {
            logout();
        }
    }

    return (
        <AuthContext.Provider value={{
            user,
            details,
            login,
            logout,
            register,
            resendConfirmation,
            originalAdminSession,
            baseUrl,
            hasPermission,
            updatePassword,
            getUsers,
            getUser,
            getUserAudit,
            getPermissions,
            setUserRoles,
            removeUserRoles,
            adminUpdateUserPassword,
            impersonateUser,
            revertToAdminSession
        }}>
            {user ? (
                <>
                    <NavComponent />
                    <Container fluid className="pt-5 mt-4">
                        {children}
                    </Container>
                    <CopyrightComponent />
                </>
            ) : (
                <>
                    <Container fluid className="login d-flex justify-content-center align-items-center min-vh-100">
                        <Card className="col-12 col-lg-6 shadow mx-auto p-3">
                            {children}
                            <CopyrightComponent />
                        </Card>
                    </Container>
                </>
            )}
        </AuthContext.Provider>
    );
};

export default AuthContext;
